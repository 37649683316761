<template>
    <v-card id="bannerPage" tile class="secondaryColorBg pa-10 mb-8" width="100%" flat>
        <v-row justify="start" align="center">
            <v-col cols="12" sm="3" md="2" lg="1">
                <v-icon v-if="icon" size="70" class="fifthColorTx mr-4 bannerPageIcon">{{ icon }}</v-icon>
            </v-col>
            <v-col cols="12" sm="9" md="10" lg="11">
                <h1 v-if="title" class="font-weight-light fifthColorTx bannerPageTitle">{{ title }}</h1>
                <h4 v-if="subtitle && subtitle != ''" class="font-weight-light fifthColorTx bannerPageSubTitle">{{ subtitle }}</h4> 
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default ({
    props:{
        title: null,
        subtitle: null,
        icon: null,
    }
})
</script>
