<template>
    <v-container class="pt-8 pb-8 pa-0" flat id="userform">
        <BannerPage 
            :title="$t('message.user_form_header')"
            :subtitle="$t('message.user_form_header_text')"
            icon="mdi-account-circle">
        </BannerPage>
        <v-skeleton-loader tile type="article, article" :loading="false">

            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>                    
            </v-overlay>
            <v-form ref="userformForm" v-model="valid">
                <v-card tile class="border" width="100%" flat>
                    <v-card-text class="userform_content">
                    <v-row class="userform_row">
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field class="userform_input"
                                v-model="keyClokMail" 
                                :label="$t('message.user_form_username_label') + '*'" 
                                :hint="$t('message.user_form_username_comment')"
                                :persistent-hint="$t('message.user_form_username_comment') && $t('message.user_form_username_comment').length > 0 ? true : false"
                                :rules="emailRules">
                                </v-text-field> 
                            </v-col>
                    </v-row>
                    <v-row>
                        <v-btn :disabled="keyClokMail != originMail" 
                            class="userform_btn primaryColorBg white--text mr-2" 
                            @click="changePassword()">{{$t('message.user_form_change_password_button_name')}}
                        </v-btn> 
                        <v-btn :disabled="!valid || keyClokMail == originMail" 
                            class="userform_btn primaryColorBg white--text"
                            @click="saveProfile()">
                            <v-icon v-if="keyClokMail != originMail && valid" >
                                mdi-exclamation
                            </v-icon>
                            {{$t('message.user_form_change_username_button_name')}}
                        </v-btn> 
                    </v-row>
                    </v-card-text>
                </v-card>  
            </v-form>
        </v-skeleton-loader>
    
        <Confirmation ref="myConfirmation"></Confirmation>
        <Snackbar ref="mySnackbar"></Snackbar> 
    </v-container>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";
import BannerPage from './BannerPage.vue';
import Snackbar from './Snackbar.vue';
import Confirmation from './Confirmation.vue';


export default {
    name:'MyProfile',
    components:{
        BannerPage,
        Snackbar,
        Confirmation
    },
    data() {
        return {
            valid: false,
            emailRules: [this.checkRequired, this.checkEmail],
            originMail: null,
            overlay: false,
            keyClokData: null,
            keyClokMail: null,
        }        
    },
    methods:{
        checkEmail: function (value) {
            if (value == null || value.toString().length == 0)
                return true;
            return /^.+@.+\..+$/.test(value) || this.$t("message.useform_check_email");
        },
        checkRequired: function (value) {
            if (value == null || value.toString().length == 0)
                return this.$t("message.useform_check_text_required");
            return true;
        },
		getKeycloak: function (node) {
			if (node == null)
				return null;
			if (node.$options.name != "App")
				return this.getKeycloak(node.$parent);
			return node.keycloak;
		},  
        saveProfile: async function () {
            var that = this;
            that.$refs.mySnackbar.close();
            if (await that.$refs.myConfirmation.open(
                that.$t("message.useform_change_username"),
                that.$t("message.useform_change_username_text"),

                true,
                { 'accept': this.$t("message.useform_accept"), 'decline': this.$t("message.useform_decline") }

            )) {
                var success = null;
                var message = 'Impossibile salvare i dati.';
                that.overlay = true;
                var json = {
                    "cod_client" : that.$cod_client.value,
                    "email" : that.keyClokMail
                };
                services.apiCallerCustomer.saveProfile("userform", json)
                    .then((res) => {
                        if(res.data != null)
                        {
                            success = res.data.success ? 'success' : null;
                            message = res.data.message;

                            if(res.data.username_changed) {
                                var logoutOptions = { redirectUri : window.location.origin };
                                that.keyClokData.logout(logoutOptions);
                            }
                        }                    
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        that.overlay = false;
                        that.$refs.mySnackbar.open(message, success);
                    });
            }  
        },  
        changePassword: async function () {
            var that = this;
            if (await that.$refs.myConfirmation.open(
                that.$t("message.useform_change_password"),
                that.$t("message.useform_change_password_text"),

                true, 
                { 
                    'accept': that.$t('message.useform_accept'),
                    'decline': that.$t('message.useform_decline')
                }
            )) {
                this.overlay = true;
                services.apiCallerCustomer.changePassword()
                    .then(() => {
                        that.overlay = false;
                        var logoutOptions = { redirectUri : window.location.origin };
                        that.keyClokData.logout(logoutOptions);
                    })
                    .catch((error) => {
                        console.log(error);
                        
                        that.$refs.mySnackbar.open(that.$t('message.useform_change_password_error'));
                    });
            }     
        }, 
        profileRules: function (field) {
            var rules = [];
            if(field.required) {
                rules.push(this.checkRequired)
            }
            
            //check format
            switch(field.format) {
                case 'textonly':
                rules.push(this.checkTextOnly);
                break;
                case 'int':
                rules.push(this.checkInt);
                break;
                case 'decimal':
                rules.push(this.checkDecimal);
                break;
                case 'email' :
                rules.push(this.checkEmail);
                break;
                case 'phone' :
                rules.push(this.checkPhone);
                break;
                default:
                break;
            }

            return rules;
        },  
    },
    created() {
        var that = this;
        that.keyClokData = that.getKeycloak(this.$parent)
        that.keyClokMail = that.keyClokData.idTokenParsed.email;
        that.originMail = that.keyClokData.idTokenParsed.email;
    },
}
</script>
