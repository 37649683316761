<template>
    <v-dialog v-model="dialog"
              :persistent="persistent"
              :max-width="options.maxWidth"
              :width="options.width"
              @keydown.esc="escKeydown">
        <v-card id="confirmation">
            <v-card-title v-show="!!title" class="confirmation-title headline">
                {{title}}
            </v-card-title>

            <v-card-text v-show="!!text" class="confirmation-text">
                <span v-html="text"></span>
            </v-card-text>

            <v-card-actions class="confirmation-actions">
                <v-spacer></v-spacer>

                <v-btn v-if="buttonLabels.decline && buttonLabels.decline.length > 0" 
                    class="secondaryColorBg confirmation-button-cancel" dark  @click="cancel">
                    {{buttonLabels.decline}}
                </v-btn>

                <v-btn v-if="buttonLabels.accept && buttonLabels.accept.length > 0" 
                    class="secondaryColorBg confirmation-button-accept" dark  @click="accepted">
                    {{buttonLabels.accept}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'confirmation',
        data() {
            return {
                buttonLabels: {
                    accept: null,
                    decline: null
                },
                dialog: false,
                options: {
                    maxWidth: null,
                    width: null
                },
                persistent: true,
                resolve: null,
                reject: null,
                text: null,
                title: null
            };
        },
        methods: {
            accepted: function () {
                this.resolve(true);
                this.dialog = false;
            },
            cancel: function () {
                this.resolve(false);
                this.dialog = false;
            },
            escKeydown: function () {
                if (this.persistent)
                    return;
                this.cancel();
            },
            open: function (title, text, persistent, buttonLabels, options) {
                var that = this;

                that.title = typeof title === 'undefined' ? null : title;
                that.text = typeof text === 'undefined' ? null : text;
                that.persistent = typeof persistent === 'undefined' || persistent == null ? true : persistent;

                var obj = {
                    accept: this.$t("message.confirmation_yes"),
                    decline: this.$t("message.confirmation_no")
                };
                
                if (typeof buttonLabels !== 'undefined' && buttonLabels) {
                    obj = {
                        accept: Object.prototype.hasOwnProperty.call(buttonLabels, 'accept') ? buttonLabels['accept'] : this.$t("message.confirmation_yes"),
                        decline: Object.prototype.hasOwnProperty.call(buttonLabels, 'decline') ? buttonLabels['decline'] : this.$t("message.confirmation_no")
                    };
                }
                that.buttonLabels = Object.assign({}, obj);

                var opt = {
                    width: 500,
                    maxWidth: null
                };

                if (typeof options != 'undefined' && options) {
                    opt = {
                        width: Object.prototype.hasOwnProperty.call(options, 'width') && options['width'] ? options['width'] : null,
                        maxWidth: Object.prototype.hasOwnProperty.call(options, 'maxWidth') && options['maxWidth'] ? options['maxWidth'] : null
                    };
                }
                that.options = Object.assign({}, opt);

                that.dialog = true;

                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            }
        }
    };
</script>